import React, { useEffect, useState } from 'react';
import './Home.css';
import content1 from '../../data/article1.json';
import content2 from '../../data/article2.json';
import Article from './types/Article';

const Home = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    setArticles([content2.article, content1.article]);
  }, []);

  const copyToClipboard = (code: string, index: string) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      const preElement = document.getElementById(`code-block-${index}`);
      if (preElement) {
        preElement.classList.add('copied');
        setTimeout(() => {
          preElement.classList.remove('copied');
          setCopied(false);
        }, 2000); // Hide after 2 seconds
      }
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const sharePost = (title: string) => {
    const url = `${window.location.href}#${title}`;
    if (navigator.share) {
      navigator.share({
        title: title,
        url: url
      }).then(() => {
        console.log('Post shared successfully');
      }).catch(err => {
        console.error('Failed to share post: ', err);
      });
    } else {
      console.error('Share API not supported in this browser');
    }
  };

  if (articles.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="home">
      {articles.map((article, index) => (
        <div key={index} id={article.title} className="post">
          <h1><a href={`#${article.title}`}>{article.title}</a></h1>
          <div className="post-subheader">
            <div className="post-date">{article.date}</div>
            <button
              className="share-btn"
              onClick={() => sharePost(article.title)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-share"
              >
                <path d="M4 12v7a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7"></path>
                <polyline points="16 6 12 2 8 6"></polyline>
                <line x1="12" y1="2" x2="12" y2="15"></line>
              </svg>
            </button>
          </div>
          {article.sections.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              {section.heading && <h2>{section.heading}</h2>}
              {section.subHeading && <h3>{section.subHeading}</h3>}
              <p>{section.content}</p>
              {section.code && (
                <pre id={`code-block-${index}-${sectionIndex}`}>
                  <code>{section.code}</code>
                  <button
                    className="copy-btn"
                    onClick={() => copyToClipboard(section.code!, `${index}-${sectionIndex}`)}
                  >
                    <i className="clipboard-icon"></i>
                  </button>
                </pre>
              )}
            </div>
          ))}
          <div className="end-of-article">
            <div className="artistic-pattern"></div>
            <div className="mvb-text">MvB</div>
            <div className="artistic-pattern"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Home;