import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Theo from './pages/Theo/Theo';

const App = () => {
  return (
    <Router>
      <div>
        <nav className="menu">
          <Link to="/">Home</Link>
          <Link to="/about">About Me</Link>
          <Link to="/theo">Theo</Link>
        </nav>
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path='/theo' element={<Theo />} />
            <Route path="/about/" element={<About />} />
            <Route path="/theo/" element={<Theo />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;