import './Theo.css';
import Spiderman from '../../assets/images/spiderman.jpg';

const Theo = () => {
  return (
    <div className="theo-container">
      <p>My name is Theo. I am 3. I like Spiderman.</p>
      <img src={Spiderman} alt="Spiderman" className="spiderman-photo" />
    </div>
  );
};

export default Theo;