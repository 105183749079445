import React from 'react';
import './About.css';
import profilePhoto from '../../assets/images/matt.jpg';

const About = () => {
  return (
    <div className="about-me">
      <div className="profile">
        <div className="description">
          <h1>Matthew van Bird</h1>
          <p>
            I am a Lead Software Engineer, a father of two, and a husband, living in the Midlands of the UK.
          </p>
          <p>
            Over the past decade, I've had the opportunity to work on a diverse range of development projects. From embedded systems for scientific instruments, crazy ideas in startups, open banking initiatives (before it was mainstream), to applications for the financial industry, I've experienced a fair bit, good and bad.
          </p>
          <p>
            My expertise spans multiple languages and frameworks, but my current focus is on C# and .NET within an AWS ecosystem.
          </p>
          <p>
            I am not, and never will be a frontend developer. I have a deep respect for those who can make things look good and work well. I am not one of those people. I am a backend developer through and through.
          </p>
        </div>
        <div className="contact-container">
          <img src={profilePhoto} alt="MvB" className="profile-photo" />
          <div className="contact-details">
            <h2>Contact Details</h2>
            <p>Feel free to reach out to me should you wish to.</p>
            <p>Email: <a href="mailto:matt.van.bird@proton.me">matt.van.bird@proton.me</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;